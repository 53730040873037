import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class PrivacyPage extends React.Component
{
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const menu = data.site.siteMetadata.menuLinks
        const tagline = data.site.siteMetadata.tagline
    
        return (
          <Layout location={this.props.location} title={siteTitle} menuLinks={menu} tagline={tagline}>
            <SEO title="Privacy" />
            <h1>Derek's Privacy Page</h1>
            
            <p>I respect your privacy. And it is my policy not to share or sell any information you provide me.</p>

            <h2>This web site</h2>

            <p>We use cookies on this website to collect non-personally identifiable information. This helps us detect details such as browser type and plug-ins, and information about which pages visitors go to and when. If you have any questions about this policy, please <a href="/contact">contact us</a>.</p>

            <h2>My newsletter and mailing lists</h2>

            <p>I use MailChimp to keep in touch with customers, potential customers, as well as individuals who have attended my workshops, conference talks, or other presentations. If at any time you decide you do not want to receive email communication from me, simply click the link at the bottom of the email to unsubscribe. We will remove your name and email address from our list, and will do the same with your name, title, business address and business phone number upon request. Alternatively, <a href="/contact/">contact me directly.</a></p>
             
          </Layout>
        )
      }

}

export default PrivacyPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        tagline
        menuLinks {
          name
          link
        }
      }
    }
  }
`